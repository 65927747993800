

import React from 'react';
import TokenPage from './Token';

const Pin = ({
    title  = "Login Pin" ,
    tokenNumber ,
    myPin
}:{
    title?: string
    tokenNumber?: number
    myPin? : any
}) => {

    return(
        <section>


                <TokenPage myPin = {myPin} tokenNumber={tokenNumber} title={title} />

            </section>


    )
}

export default Pin;