import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import GetCookieValue from "utils/GetCookieValue";



export const api = createApi({
  reducerPath: "unauth_user",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API,
  }),
  endpoints: (builder) => ({}),
  tagTypes: ["Auth", "Card"],
});

// Auth API

const BaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API,
  prepareHeaders: (headers, { getState }) => {
     const cookie = GetCookieValue("jara_access");
    headers.set("authorization", `Bearer ${cookie}`);
    return headers;
  },
});




export const authApi = createApi({
  reducerPath: "auth_user",
  baseQuery: BaseQuery,
  endpoints: (builder) => ({}),
  tagTypes: [
    "Profile",
    "Auth",
    "Subscriptions",
    "Card",
    "Beneficiary",
    "Rewards",
    "PaymentOptions",
    "gift",
  ],
});
