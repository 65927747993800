import React, { useState } from "react";
import style from "./leftmenu.module.scss";
import { setName } from "features/Services/GiftCardService/GiftCardServiceSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  Input,
  InputGroup,
  Icon,
  InputRightElement,
  AvatarBadge,
  Avatar,
  InputLeftElement,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  useBreakpoint,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { MdClose, MdShoppingCart } from "react-icons/md";
import { useDebounce } from "react-use";
import { useNavigate } from "react-router-dom";


const CardSearch = () => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<string>("");
  const { cart } = useAppSelector((state) => state.giftCardService);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const breakpoint = useBreakpoint();
  const navigate = useNavigate();

  useDebounce(
    () => {
      dispatch(setName(value));
    },
    500,
    [value]
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  const clearInput = () => {
    setValue("");
  };

  const handleOpen = () => {
    if (breakpoint === "base" || breakpoint === "sm") {
      onOpen();
    }
  };

  const handleNavigate = () => {
    navigate("/services/gift/cart");
  };

  return (
    <Flex align={"center"} gap={".5rem"}>
      <section className={style.search} onClick={handleOpen}>
        <InputGroup size={{ lg: "lg", base: "sm" }}>
          <InputLeftElement>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                stroke="#363A3F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21 21.0004L16.65 16.6504"
                stroke="#363A3F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </InputLeftElement>
          <Input
            onChange={handleChange}
            type="text"
            placeholder="Search for gift cards and more..."
            border={"1px solid !important"}
            borderColor={"#929EAE !important"}
            borderRadius="lg"
            value={value}
            w={{
              lg: "auto",
              base: "0",
            }}
          />
          {value && (
            <InputRightElement
              children={<Icon onClick={clearInput} as={MdClose} />}
            />
          )}
        </InputGroup>
      </section>
      {cart.length > 0 ? (
        <Avatar
          cursor={"pointer"}
          bg={"white"}
          size={{ md: "md", base: "sm" }}
          icon={
            <Icon
              fontSize={{ lg: "2rem", base: "sm" }}
              color={"black"}
              as={MdShoppingCart}
            />
          }
          onClick={handleNavigate}
        >
          <AvatarBadge boxSize={{ lg: "1.25em", base: 4 }} bg="blue.500">
            {cart.length > 0 && cart.length}
          </AvatarBadge>
        </Avatar>
      ) : null}
      <Modal isOpen={isOpen} onClose={onClose} size={"sm"}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody mx={".5rem"} p={"2rem 1rem"}>
            <InputGroup size={"lg"} mt={"3rem"}>
              <InputLeftElement>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                    stroke="#363A3F"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21 21.0004L16.65 16.6504"
                    stroke="#363A3F"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </InputLeftElement>
              <Input
                onChange={handleChange}
                type="text"
                placeholder="Search for gift cards and more..."
                border={"1px solid !important"}
                borderColor={"#929EAE !important"}
                borderRadius="lg"
                value={value}
              />
              {value && (
                <InputRightElement
                  children={<Icon onClick={clearInput} as={MdClose} />}
                />
              )}
            </InputGroup>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default CardSearch;
