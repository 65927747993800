import React from 'react'
import style from './modal.module.scss'

interface modal {
    handleClose: () => void;
    open: boolean;

    children: React.ReactNode
}
const Modal: React.FC<modal> = ({
    handleClose,
    open,
    children
}) => {
    //close the modal when the component on click outside child

    // const handleClickOutside = () => {
    //     if (open) {
    //         handleClose();
    //     }
    // };


    return (
        <div >
            <div className={style.modal} >

                {children}

            </div>
        </div>

    )
}

export default Modal